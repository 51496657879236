//import '@fortawesome/fontawesome-free/js/all.js';


/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
if(document.querySelector('form.wpcf7-form')) {
  const formCF7 = document.querySelector('form.wpcf7-form');
  document.addEventListener( 'wpcf7submit', function( event ) {
      
    // find only disbaled submit buttons
    const button = formCF7.querySelector('.wpcf7-submit[disabled]');

    // grab the old value
    const old_value = button.getAttribute('data-value');

    // enable the button
    button.removeAttribute('disabled');

    // put the old value back in
    button.value = old_value;

  }, false );

  formCF7.addEventListener('submit',function() {
    if(formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

    const button = formCF7.querySelector('input[type=submit]');
    const current_val = button.value;

    // store the current value so we can reset it later
    button.setAttribute('data-value', current_val);

    // disable the button
    button.setAttribute("disabled", true);

    // tell the user what's happening
    button.value = "Wysyłanie...";

  });
}
if(document.querySelector('.blockMovie')){
  let pageWrapperDiv = document.querySelector(".page");
  pageWrapperDiv.classList.add("fixedHeight");
}